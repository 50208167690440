/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import favicon from '../images/favicon/favicon.png';
import logo from '../images/header/logo.svg';

import { P_DOMEIN_TOKEN } from '../constants/pDomeinVerify';

function Seo({
  description,
  lang,
  meta,
  title,
  rating,
  path,
  image,
  date,
  category,
  canonicalUrl,
  noindex,
  disableTitlePostfix,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription =
          description || data.site.siteMetadata.description[lang];

        const BlogUrl = `/${lang}`;
        const PostUrl = `${path}`;
        const CategoryUrl = `${data.site.siteMetadata.siteUrl}/${lang}/${category}`;
        const context = 'http://schema.org';
        const reviewCount =
          (Math.floor(Math.random() * 8) + 1) * (+new Date() % 10);

        const schemaOrgJSONLD = [
          {
            '@context': context,
            '@type': 'Organization',
            address: 'Київ, бул. Т.Шевченка, 35',
            url: BlogUrl,
            name: data.site.siteMetadata.seoTitle[lang],
            sameAs: [
              'https://www.facebook.com/globalcredit.ua/',
              'https://www.instagram.com/globalcredit.ua/?hl=ru',
              'https://t.me/globalcredit',
              'https://twitter.com/GlobalcreditUa',
              'https://www.youtube.com/channel/UC-oNJDoDSCmOQy4eZ3_s18Q',
            ],
            telephone: '(097) 097-88-88',
          },
          {
            '@context': context,
            '@type': 'Article',
            datePublished: date,
            dateModified: date,
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': data.site.siteMetadata.siteUrl,
            },
            url: PostUrl,
            about: title,
            aggregateRating: {
              '@type': 'aggregateRating',
              ratingValue: rating,
              bestRating: 5,
              reviewCount: reviewCount === 0 ? 14 : reviewCount,
            },
            author: 'Глобал Кредит',
            publisher: {
              '@type': 'Organization',
              name: 'ТОВ ГЛОБАЛ КРЕДИТ',
              telephone: '(097) 097-88-88',
              logo: {
                '@type': 'ImageObject',
                url: favicon,
                contentUrl: logo,
                width: 408,
                height: 51,
              },
            },
            headline: title,
            inLanguage: lang,
            alternateName: title,
            image: {
              '@type': 'ImageObject',
              url: image,
            },
          },
        ];

        if (category) {
          schemaOrgJSONLD.push({
            '@context': context,
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': BlogUrl,
                  url: BlogUrl,
                  image: favicon,
                  name: 'globalcredit',
                },
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': CategoryUrl,
                  url: CategoryUrl,
                  image: favicon,
                  name: category,
                },
              },
              {
                '@type': 'ListItem',
                position: 3,
                item: {
                  '@id': PostUrl,
                  url: PostUrl,
                  image: image,
                  name: title,
                },
              },
            ],
          });
        } else if (BlogUrl !== PostUrl) {
          schemaOrgJSONLD.push({
            '@context': context,
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': BlogUrl,
                  url: BlogUrl,
                  image: favicon,
                  name: 'globalcredit',
                },
              },
              {
                '@type': 'ListItem',
                position: 2,
                item: {
                  '@id': PostUrl,
                  url: PostUrl,
                  image: favicon,
                  name: title,
                },
              },
            ],
          });
        } else {
          schemaOrgJSONLD.push({
            '@context': context,
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                item: {
                  '@id': BlogUrl,
                  url: BlogUrl,
                  image: favicon,
                  name: 'globalcredit',
                },
              },
            ],
          });
        }

        const titleTemplate = disableTitlePostfix
          ? '%s'
          : `%s | ${data.site.siteMetadata.seoTitle[lang]}`;

        return (
          <Helmet
            defer={false}
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={titleTemplate}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'p:domain_verify',
                content: P_DOMEIN_TOKEN,
              },
            ].concat(meta)}
          >
            <link rel='canonical' href={canonicalUrl ? canonicalUrl : path} />
            {noindex && <meta name='robots' content='noindex, nofollow' />}
            <script type='application/ld+json'>
              {JSON.stringify(schemaOrgJSONLD)}
            </script>
          </Helmet>
        );
      }}
    />
  );
}

Seo.defaultProps = {
  lang: 'ru',
  meta: [],
  image: favicon,
  date: '2017-02-07',
  rating: 4.6,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string,
  rating: PropTypes.number,
  path: PropTypes.string,
  image: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string,
  pagePath: PropTypes.string,
  canonicalUrl: PropTypes.string,
  noindex: PropTypes.bool,
  disableTitlePostfix: PropTypes.bool,
};

export default Seo;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        seoTitle {
          ru,
          uk
        }
        description
        author
        siteUrl
      }
    }
  }
`;
